<template>
  <div>
    <v-card-title class="display-1 mb-2">{{t('$vuetify.serviceCenter.requestCharger')}}</v-card-title>
    <v-card-text>
      {{t('$vuetify.serviceCenter.contactExplication')}}
    </v-card-text>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-textarea
          :rules="[rules.required]"
          :validate-on-blur="false"
          :error="error"
          @keyup.enter="submit"
          @change="resetErrors"
          name="description"
          :label="t('$vuetify.serviceCenter.description')"
          v-model="description"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
        <v-btn
            color="primary"
            text
            @click="previousStep()"
        >
            <v-icon>mdi-chevron-left</v-icon>
            {{t('$vuetify.generic.back')}}
        </v-btn>
        <v-btn
            color="success"
            text
            @click="nextStep()"
        >
          {{t('$vuetify.generic.next')}}
        </v-btn>
        <v-btn
            color="red"
            text
            @click="cancel"
        >
          {{t('$vuetify.generic.cancel')}}
        </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      // form
      isFormValid: true,

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      isVerified: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      },
      description: ''
    }
  },
  computed: {
    ...mapGetters({ previousStepName: 'requestsState/previousStepName' })
  },
  methods: {
    cancel () {
      this.cleanPayload()
      this.$emit('cancel')
    },

    nextStep () {
      this.submit()
    },

    previousStep () {
      this.$store.commit('requestsState/setDescription', '')
      this.$store.commit('requestsState/setPreviousStepName')
    },

    cleanPayload () {
      this.description = ''
    },

    submit () {
      if (this.$refs.form.validate()) {
        this.$store.commit('requestsState/setDescription', this.description)
        this.$store.commit('requestsState/setSummary', 'Company site - request')
        this.$store.commit('requestsState/setStep', 'ContactEnterpriseDetails')
      }
    },

    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
